﻿export default function DiagramBreakpoints() {
    var Diagram = {};
    Diagram.Breakpoints = {};
    Diagram.Breakpoints.MediaQueries = {}
    Diagram.Breakpoints.Variables = {
        "alpha": "screen and (min-width: 480px)",
        "bravo": "screen and (min-width: 896px)",
        "charlie": "screen and (min-width: 1184px)",
        "delta": "screen and (min-width: 1344px)",
        "echo": "screen and (min-width: 1680px)",
    };

    let breakpoints = Object.keys(Diagram.Breakpoints.Variables);
    breakpoints.forEach(breakpoint => Diagram.Breakpoints.MediaQueries[breakpoint] = window.matchMedia(Diagram.Breakpoints.Variables[breakpoint]));

    function queryTest(evt) {
        if (evt.matches) {
            matchHandler(evt);
        } else {
            unmatchHandler(evt);
        }
    }

    function getBreakpointByKey(value) {
        return Object.keys(Diagram.Breakpoints.Variables).find(key => Diagram.Breakpoints.Variables[key] === value);
    }

    function matchHandler(evt) {
        let breakpoint = getBreakpointByKey(evt.media);
        document.dispatchEvent(new CustomEvent('breakpoint-match-' + breakpoint));
    }

    function unmatchHandler(evt) {
        let breakpoint = getBreakpointByKey(evt.media);
        document.dispatchEvent(new CustomEvent('breakpoint-unmatch-' + breakpoint));
    }

    function init() {
        let mediaQueries = Object.keys(Diagram.Breakpoints.MediaQueries);
        mediaQueries.forEach(mediaQuery => Diagram.Breakpoints.MediaQueries[mediaQuery].addEventListener('change', queryTest));
        mediaQueries.forEach(mediaQuery => queryTest(Diagram.Breakpoints.MediaQueries[mediaQuery]));
    }

    init();
};